import { ulid } from 'ulid';
import dayjs from 'dayjs';
import { ref } from 'vue';

/**
 * getConfidenceName returns a string based on the confidence percentage.
 * @param {number} confidencePercentage
 * @param {number} lowConfidenceLimit
 * @param {number} mediumConfidenceLimit
 * @param {number} highConfidenceLimit
 * @returns {string}
 */
export const getConfidenceName = (
  confidencePercentage,
  lowConfidenceLimit = 40,
  mediumConfidenceLimit = 80,
  highConfidenceLimit = 100
) => {
  if (confidencePercentage < lowConfidenceLimit) {
    return 'Low';
  } else if (confidencePercentage < mediumConfidenceLimit) {
    return 'Medium';
  } else if (confidencePercentage < highConfidenceLimit) {
    return 'High';
  } else {
    return 'Unknown';
  }
};

/**
 * getRandomItem - Returns a random item from an array.
 * @param {Array<any>} array
 * @returns {any}
 */
export const getRandomItem = (array) => {
  return array[Math.floor(Math.random() * array.length)];
};

/**
 * Returns a random integer number between min and max.
 * @param {number} min
 * @param {number} max
 * @returns
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Gets a list of strings and returns them as an unique string.
 * @param {Array<string>} fields
 * @returns {string}
 */
export const parseArrayToString = (fields) => fields.map((field) => `${field}`).join('\n');

/**
 * Generates a ULID.
 * @returns {string}
 */
export const generateULID = () => {
  return ulid();
};

/**
 * Generates a date.
 * @returns {Date}
 */
export const generateDate = () => {
  return new Date();
};

/**
 * Capitalize the first letter of a string.
 * @param {string} word
 * @returns {string}
 */
export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/**
 * Capitalize the first letter of each word in a string.
 * @param {string} words
 * @returns {string}
 */
export function capitalizeFirstLetterOfEachWord(words) {
  return words
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');
}

/**
 * Rounds a number to any level of precision
 * @param {number} num - The number to round.
 * @param {number} decimals - The decimals for the precision.
 * @returns {number}
 */
export function roundNumber(num, decimals = 0) {
  return +(Math.round(num + 'e' + decimals) + 'e-' + decimals);
}

/**
 * Merges two objects with shared properties, [obj2] will override non object params.
 * @param {object} obj1 - The first object.
 * @param {object} obj2 - The second object.
 * @returns {object} - The merged object.
 */
export function mergeObjects(obj1, obj2) {
  const result = { ...obj1 };

  for (const [key, value] of Object.entries(obj2)) {
    result[key] = result[key] && typeof value === 'object' ? { ...result[key], ...value } : value;
  }

  return result;
}

/**
 * Calculates the time difference between now and the last saved date.
 * @param {Date} currentDate - The current data.
 * @param {Date} referenceDate - The reference date from the last updated.
 * @returns {string} - The formatted date.
 */
export function formatTimeSinceSave(currentDate, referenceDate) {
  const diff = currentDate.getTime() - referenceDate.getTime();
  const diffInMinutes = Math.round(diff / 60000);
  const diffInSeconds = Math.round(diff / 1000);

  if (diffInSeconds < 0.5) return 'Saving';
  if (diffInMinutes < 1) return 'Saved just now';
  if (diffInMinutes < 60) return `Saved ${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
  if (diffInMinutes < 1440) {
    const hours = Math.round(diffInMinutes / 60);
    return `Saved ${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  const days = Math.round(diffInMinutes / 1440);
  return `Saved ${days} day${days > 1 ? 's' : ''} ago`;
}

/**
 * Returns the last item of an array.
 * @param {Array<any>} array
 * @returns {any}
 */
export function getArrayLastItem(array) {
  if (!array || array.length === 0) return null;
  return array[array.length - 1];
}

/**
 * Splits an array into chunks of a specific size.
 * @param {Array<any>} array
 * @param {number} chunkSize
 * @returns
 */
export function splitArrayIntoChunks(array, chunkSize) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

/**
 * Parses a JSON Web Token (JWT) and returns its payload.
 * @param {string} token
 * @returns
 */
export function parseJwt(token) {
  try {
    // Split the token into its components
    const [header, payload, signature] = token.split('.');

    // Base64URL decode the payload
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    // Parse the payload as JSON
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error('Invalid token', e);
    return null;
  }
}

/**
 * Formats a given date into long en-US.
 * @param {Date} date - The date to format
 * @returns {string}
 */
export function formatToLongUSDate(date = new Date()) {
  if (!(date instanceof Date)) return '';

  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

/**
 * Parses a number from a string.
 * @param {string} value - The value to parse
 * @returns {number}
 */
export function parseNumber(value) {
  // Check if the value is an empty string or contains only whitespace
  if (typeof value === 'string' && value?.trim() === '') {
    return NaN;
  }

  // Attempt to parse the number
  const parsed = Number(value);

  // Check if the parsed value is a valid finite number
  if (isNaN(parsed) || !isFinite(parsed)) {
    return NaN;
  }

  return parsed;
}

/**
 * Parses a string to kebab-case.
 * @param {string} str - The value to parse
 * @returns {string} - The kebab-case string
 */
export const toKebabCase = (str) => {
  // Check if the input is a string
  if (typeof str !== 'string') return '';

  return str
    .trim() // Remove leading/trailing spaces
    .replace(/([a-z])([A-Z])/g, '$1-$2') // Convert camelCase to kebab-case
    .toLowerCase()
    .replace(/_/g, '-') // Convert underscores to hyphens
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Convert spaces to hyphens
    .replace(/-+/g, '-'); // Convert multiple consecutive hyphens to a single hyphen
};

/**
 * Gets the current status of an alert from its status history.
 * @param {Object} alert
 * @returns
 */
export const getAlertStatus = (alert) => {
  if (!alert) return 'Unknown';

  const statusHistory = alert.statusHistory;
  if (!statusHistory || statusHistory.length === 0) return 'No status';

  return statusHistory[statusHistory.length - 1].status;
};

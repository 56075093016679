const env = {
  VITE_APOLLO_HTTP_SERVER_URL:
    window['env']['VITE_APOLLO_HTTP_SERVER_URL'] || 'http://localhost:4000/graphql',
  VITE_APOLLO_WS_SERVER_URL:
    window['env']['VITE_APOLLO_WS_SERVER_URL'] || 'ws://localhost:4000/graphql',
  VITE_CASDOOR_SERVER_URL: window['env']['VITE_CASDOOR_SERVER_URL'] || 'http://localhost:8000',
  VITE_AUTH_ACCESS_TOKEN_NAME: window['env']['VITE_AUTH_ACCESS_TOKEN_NAME'] || 'access_token',
  VITE_AUTH_REFRESH_TOKEN_NAME: window['env']['VITE_AUTH_REFRESH_TOKEN_NAME'] || 'refresh_token',
  VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME:
    window['env']['VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME'] || 'access_token_expires_at',
  VITE_FACILITY_ID_NAME: window['env']['VITE_FACILITY_ID_NAME'] || 'facility_id',
  VITE_CLIENT_ID_NAME: window['env']['VITE_CLIENT_ID_NAME'] || 'client_id',
  VITE_CLIENT_ORGANIZATION_NAME:
    window['env']['VITE_CLIENT_ORGANIZATION_NAME'] || 'organization_name',
  VITE_CLIENT_APP_NAME: window['env']['VITE_CLIENT_APP_NAME'] || 'app_name',
  VITE_FILE_HUB_API_SERVER_URL:
    window['env']['VITE_FILE_HUB_API_SERVER_URL'] || 'http://localhost:4000',
  VITE_AUTH_USER_PERMISSIONS_NAME:
    window['env']['VITE_AUTH_USER_PERMISSIONS_NAME'] || 'user_permissions'
};

export default env;

import { ref } from 'vue';
import { ulid } from 'ulid';

const toasts = ref([]);

export function useToast() {
  function createToast(message, options = {}) {
    const id = `toast-${ulid()}`;

    const toastData = {
      id,
      message,
      ...options
    };

    toasts.value.push(toastData);

    // Automatically remove the toast after a delay
    setTimeout(() => {
      removeToast(id);
    }, options.timeout || 5000);
  }

  function removeToast(id) {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  }

  return { toasts, createToast, removeToast };
}

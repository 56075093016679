import { createRouter, createWebHistory } from 'vue-router';
import env from '@/config/config';
import Cookies from 'js-cookie';
import { usePermissions } from '@/composables/ui/usePermissions';
import { PERMISSIONS } from '@/utils/strings';

const router = createRouter({
  history: createWebHistory(env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/auth/LoginView.vue'),
      meta: {
        requiresAuth: false,
        title: 'Login'
      }
    },
    {
      path: '/facility-selector',
      name: 'facility-selector',
      component: () => import('../views/facility-selector/IndexView.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/operator-alerts/:alertId/device-gallery',
      name: 'operator-alerts-device-gallery',
      component: () =>
        import('../views/operator-alerts/views/device-gallery/AlertDeviceGallery.vue'),
      meta: { requiresAuth: true, title: 'Alert Device Gallery' }
    },
    {
      path: '/',
      component: () => import('../layouts/DashboardLayout.vue'),
      meta: { requiresAuth: true, requiresFacility: true },
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('../views/dashboard/IndexView.vue'),
          meta: { title: 'Home' }
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('../views/dashboard/IndexView.vue'),
          meta: {
            title: 'Dashboard',
            requiredPermissions: [
              PERMISSIONS.VIEW_LIVE_VIEW,
              PERMISSIONS.VIEW_DEVICES,
              PERMISSIONS.VIEW_ALERTS
            ]
          }
        },
        {
          path: '/topology/:floorPlanId',
          name: 'topology',
          component: () => import('../views/topology/IndexView.vue'),
          meta: { title: 'Topology', requiredPermissions: [PERMISSIONS.VIEW_LIVE_VIEW] }
        },
        {
          path: '/devices',
          name: 'devices',
          component: () => import('../views/devices/IndexView.vue'),
          meta: { title: 'Devices', requiredPermissions: [PERMISSIONS.VIEW_DEVICES] }
        },
        {
          path: '/settings',
          redirect: '/settings/facility/details',
          name: 'settings',
          component: () => import('../views/settings/IndexView.vue'),
          meta: { title: 'Settings' },
          children: [
            {
              path: 'facility',
              name: 'settings-facility',
              component: () =>
                import('../views/settings/components/facility-section/FacilitySection.vue'),
              children: [
                {
                  path: 'details',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/details/DetailsContainer.vue'
                    )
                },
                {
                  path: 'devices',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/devices/DeviceSectionList.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_SETTINGS_DEVICES] }
                },
                {
                  path: 'alerts',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/alerts/AlertThresholdList.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_SETTINGS_ALERTS] }
                },
                {
                  path: 'notifications',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/notifications/NotificationsContainer.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_SETTINGS_NOTIFICATIONS] }
                },
                {
                  path: 'users',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/users/UserList.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_USERS] }
                },
                {
                  path: 'notes',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/notes/NoteList.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_NOTES] }
                },
                {
                  path: 'files',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/files/FileList.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_FIRMWARES] }
                },
                {
                  path: 'firmware',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/FirmwareList.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_FIRMWARES] }
                },
                {
                  path: 'administration',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/AdministrationForm.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.MANAGE_ADMINISTRATION] }
                },
                {
                  path: 'environment',
                  component: () =>
                    import(
                      '../views/settings/components/facility-section/components/EnvironmentSettings.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.MANAGE_ENVIRONMENT] }
                }
              ]
            },
            {
              path: 'account',
              redirect: '/settings/account/details',
              name: 'settings-account',
              component: () =>
                import('../views/settings/components/account-section/AccountSection.vue'),
              children: [
                {
                  path: 'details',
                  component: () =>
                    import(
                      '../views/settings/components/account-section/components/DetailsContainer.vue'
                    )
                },
                {
                  path: 'terms-and-conditions',
                  component: () =>
                    import(
                      '../views/settings/components/account-section/components/TermsAndConditions.vue'
                    )
                },
                {
                  path: 'privacy-policy',
                  component: () =>
                    import(
                      '../views/settings/components/account-section/components/PrivacyPolicy.vue'
                    )
                },
                {
                  path: 'my-permissions',
                  component: () =>
                    import(
                      '../views/settings/components/account-section/components/MyPermissions.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.VIEW_PERMISSIONS] }
                }
              ]
            },
            {
              path: 'controller',
              redirect: '/settings/controller/details',
              name: 'settings-controller',
              component: () =>
                import('../views/settings/components/controller-section/ControllerSection.vue'),
              children: [
                {
                  path: 'details',
                  component: () =>
                    import(
                      '../views/settings/components/controller-section/components/details/DetailsContainer.vue'
                    )
                },
                {
                  path: 'migrations',
                  component: () =>
                    import(
                      '../views/settings/components/controller-section/components/database/MigrationsContainer.vue'
                    ),
                  meta: { requiredPermissions: [PERMISSIONS.MANAGE_MIGRATIONS] }
                }
              ]
            }
          ]
        },
        {
          path: '/evac-plans',
          name: 'evac-plans',
          component: () => import('../views/evac-plans/views/index/IndexView.vue'),
          meta: { title: 'Evac Plans', requiredPermissions: [PERMISSIONS.VIEW_EVAC_PLANS] }
        },
        {
          path: '/evac-plan-builder',
          name: 'evac-plan-builder',
          component: () => import('../views/evac-plans/views/evac-plan-builder/EvacPlanView.vue'),
          meta: { title: 'Evac Plan Builder', requiredPermissions: [PERMISSIONS.MANAGE_EVAC_PLAN] }
        },
        {
          path: '/reports',
          name: 'reports',
          component: () => import('../views/reports/IndexView.vue'),
          meta: { title: 'Reports', requiredPermissions: [PERMISSIONS.VIEW_ALERTS] }
        },
        {
          path: '/operator-alerts',
          name: 'operator-alerts',
          component: () => import('../views/operator-alerts/views/index/IndexView.vue'),
          meta: { title: 'Alerts', requiredPermissions: [PERMISSIONS.VIEW_ALERTS] }
        },
        {
          path: '/operator-alerts/:alertId/live/device/:deviceEntityId',
          name: 'operator-alerts-live-device',
          component: () => import('../views/operator-alerts/views/live/AlertLiveView.vue'),
          meta: { title: 'Live', requiredPermissions: [PERMISSIONS.VIEW_ALERTS] }
        },
        {
          path: '/live-view',
          name: 'live-view',
          component: () => import('../views/live-view/IndexView.vue'),
          meta: { title: 'Live View', requiredPermissions: [PERMISSIONS.VIEW_LIVE_VIEW] }
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'not-found',
          component: () => import('../views/not-found/IndexView.vue')
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  const requiredPermissions = to.meta.requiredPermissions;
  const requiresFacility = to.meta.requiresFacility;
  const loggedIn =
    Cookies.get(env.VITE_AUTH_ACCESS_TOKEN_NAME) != undefined &&
    Cookies.get(env.VITE_AUTH_REFRESH_TOKEN_NAME) != undefined &&
    Cookies.get(env.VITE_AUTH_ACCESS_TOKEN_EXPIRES_AT_NAME) != undefined;
  const facilityId = Cookies.get(env.VITE_FACILITY_ID_NAME);
  const { hasAnyPermission } = usePermissions();

  if (requiresAuth && !loggedIn) {
    return next('/login');
  }

  if (requiresFacility && !facilityId) {
    return next('/facility-selector');
  }

  if (requiredPermissions) {
    if (!hasAnyPermission(requiredPermissions)) {
      return next('/not-found');
    }
  }

  if (to.path === '/login' && loggedIn) {
    // Read the redirectPath cookie and redirect to it if it exists
    const redirectPath = Cookies.get('redirectPath');
    if (redirectPath) {
      return next(redirectPath);
    }
    return next('/');
  }

  if (to.path === '/facility-selector' && facilityId) {
    const redirectPath = Cookies.get('redirectPath');
    if (redirectPath) {
      return next(redirectPath);
    }
    return next('/');
  }

  next();
});

const DEFAULT_TITLE = 'SafeEVAC';
router.afterEach((to, from) => {
  document.title = DEFAULT_TITLE + (to.meta.title ? ' | ' + to.meta.title : '');
});

export default router;

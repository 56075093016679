<script setup>
import { RouterView } from 'vue-router';
import AlertsStack from '@/components/AlertsStack.vue';
import { useAlerts } from '@/stores/useAlerts';
import { onMounted } from 'vue';

const alertsStore = useAlerts();

onMounted(async () => {
  var cookies = [];
  var loggedIn = false;
  try {
    cookies = Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')));
    if(cookies["access_token"] != null) {
      loggedIn = true;
    }
  } catch (e) {
    console.error('Error parsing cookies', e);
  }
  if(loggedIn) {
    console.log('We are logged in so connecting to websockets');
    await alertsStore.fetchAlerts();
    alertsStore.initSubscriptions();
  } else {
    console.log('We aren\'t logged in so not connecting to websockets');
  }

});
</script>

<template>
  <RouterView />
  <AlertsStack />
  <!-- Load assets globally -->
  <SvgAssets />
  <RightBarSvgAssets />
</template>

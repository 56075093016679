import env from '@/config/config';
import Cookies from 'js-cookie';

export function usePermissions() {
  /**
   *
   * @param {Array<string>} permissions
   * @returns
   */
  function hasAnyPermission(permissions) {
    const userPermissionsCookie = Cookies.get(env.VITE_AUTH_USER_PERMISSIONS_NAME);
    if (!userPermissionsCookie) {
      clearCookies();
      window.location.reload();
    }
    try {
      const userPermissions = JSON.parse(userPermissionsCookie);
      if (userPermissions) {
        return userPermissions.some((permission) => permissions.includes(permission));
      }
    } catch (e) {
      clearCookies();
      window.location.reload();
    }
  }

  function hasPermission(permission) {
    return hasAnyPermission([permission]);
  }

  function hasRole(role) {
    const userPermissions = JSON.parse(Cookies.get(env.VITE_AUTH_USER_PERMISSIONS_NAME)) || [];
    return userPermissions.includes(role);
  }

  function getPermissions() {
    const userPermissions = JSON.parse(Cookies.get(env.VITE_AUTH_USER_PERMISSIONS_NAME)) || [];
    return userPermissions;
  }

  function clearCookies() {
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
  }

  return { hasAnyPermission, hasPermission, hasRole, getPermissions };
}

import { DefaultApolloClient } from '@vue/apollo-composable';
import { ApolloClient, split, HttpLink, ApolloLink } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { InMemoryCache } from '@apollo/client/core';
import env from './config';
import Cookies from 'js-cookie';
/**
 * Gets the access token for auth from local storage.
 * @returns {(string | null)}
 */
const getAccessToken = () => {
  const token = Cookies.get(env.VITE_AUTH_ACCESS_TOKEN_NAME);
  return token ? `Bearer ${token}` : null;
};

// Create standard http link pathway
const httpLink = new HttpLink({
  uri: env.VITE_APOLLO_HTTP_SERVER_URL,
  headers: {
    authorization: getAccessToken()
  }
});

// Setup typename cleaner, this strips the __typename from the variables if they come in which throws of graphql inputs
const cleanTypeName = new ApolloLink((operation, forward) => {
  const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation);
});

// Combine regular http link with the typename cleaner
const httpLinkWithCleaner = ApolloLink.from([cleanTypeName, httpLink]);

// Create websocket pathway
const wsLink = new GraphQLWsLink(
  createClient({
    url: env.VITE_APOLLO_WS_SERVER_URL,
    connectionParams: {
      Authorization: getAccessToken()
    },
    options: {
      reconnect: true
    }
  })
);

// Create split link pathway that routes the two
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLinkWithCleaner
);

const apolloClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  }
});

export { DefaultApolloClient, apolloClient };

<script setup>
import { useToast } from '@/composables/ui/useToast';
import { onMounted, ref, watch } from 'vue';
import { Toast } from 'bootstrap';

const { toasts, removeToast } = useToast();
const toastsShowedIds = ref([]);

onMounted(() => {
  watch(
    toasts,
    (newToasts) => {
      newToasts.forEach((toast) => {
        if (toastsShowedIds.value.includes(toast.id)) {
          return;
        }
        const toastEl = document.createElement('div');
        toastEl.className = 'toast';
        toastEl.innerHTML = `
                    <div class="toast-header">
                        <strong class="me-auto">Notification</strong>
                        <button type="button" class="btn-close" data-bs-dismiss="toast"></button>
                    </div>
                    <div class="toast-body">${toast.message}</div>
                `;

        document.getElementById('alerts-stack').appendChild(toastEl);

        const bsToast = new Toast(toastEl);
        bsToast.show();

        // Remove the toast from DOM when hidden
        toastEl.addEventListener('hidden.bs.toast', () => {
          toastEl.remove();
          removeToast(toast.id);
        });

        toastsShowedIds.value.push(toast.id);
      });
    },
    { deep: true }
  );
});
</script>

<template>
  <div id="alerts-stack" class="toast-container position-fixed top-0 end-0 p-3">
    <!-- Dynamic toast will be here -->
  </div>
</template>

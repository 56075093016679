import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'bootstrap';
import '../scss/custom.scss';

import { createApp, provide, h } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import Vue3Lottie from 'vue3-lottie';
import { DefaultApolloClient, apolloClient } from './config/apolloClient';
import SvgAssets from '@/components/SvgAssets.vue';
import RightBarSvgAssets from '@/components/RightBarSvgAssets.vue';

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App)
});

app.use(createPinia());
app.use(router);
app.use(Vue3Lottie);
app.component('SvgAssets', SvgAssets);
app.component('RightBarSvgAssets', RightBarSvgAssets);

app.mount('#app');
